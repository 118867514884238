import React from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import DocExport from "components/PDF/DocExportPage";

function parseIndicators(indicators) {
  try {
    const parsedIndicatorsr = JSON.parse(indicators);
    return Array.isArray(parsedIndicatorsr) ? parsedIndicatorsr : [];
  } catch (error) {
    return [];
  }
}

function DocExportPage() {
  const { search } = useLocation();
  const [searchParams] = useSearchParams(search);
  const api = useSelector((state) => state.API);

  React.useEffect(() => {
    const token = searchParams.get("token");
    if (!api || !token) return;
    api.setToken(token);
  }, [api, searchParams]);

  if (!api) return null;

  const indicators = searchParams.get("indicators");

  return <DocExport api={api} indicators={parseIndicators(indicators)} />;
}

export default React.memo(DocExportPage);
