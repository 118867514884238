import React from "react";
import styled from "styled-components";
import { createPortal } from "react-dom";
import { observer } from "mobx-react-lite";

import { AbstractVirtualizedListEntity } from "./VirtualizedListEntity";

import ListItem from "./ListItem";

export interface VirtualizedListItemInterface {
  label: string;
  id: string | number;
}

interface VirtualizedListInterface<DataType extends VirtualizedListItemInterface> {
  entity: AbstractVirtualizedListEntity<DataType>;
}

function VirtualizedList<DataType extends VirtualizedListItemInterface>({ entity }: VirtualizedListInterface<DataType>) {
  const containerRef = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    entity.updateContainerRef(container);
  }, [entity]);

  const handleMouseMove = React.useCallback(
    (event: MouseEvent) => {
      entity.updateDragPositions(event.clientX + 16, event.clientY + 8);
    },
    [entity]
  );

  React.useEffect(() => {
    if (!entity.dragging) return;

    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mouseup", entity.stopDrag);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", entity.stopDrag);
    };
  }, [handleMouseMove, entity.dragging, entity.stopDrag]);

  const handleScroll = React.useCallback(() => {
    const container = containerRef.current;
    if (!container) return;

    const topIndex = Math.floor(container.scrollTop / 24);
    if (topIndex !== entity.startIndex) entity.onListScroll(topIndex);
  }, [entity]);

  return (
    <VirtualizedListContainerStyles ref={containerRef} onScroll={handleScroll}>
      <VirtualizedListContentContainerStyles>
        {entity.visibleDataWithNestingElements.map((item) => (
          <ListItem key={item.id} item={item} entity={entity} />
        ))}
      </VirtualizedListContentContainerStyles>
      <div style={{ minHeight: `calc(${(entity.dataWithNestingElements.length - 1) * 24}px` }} />
      {entity.dragging &&
        createPortal(
          <VirtualizedListDragContainerStyles style={{ left: entity.dragX, top: entity.dragY }}>
            Выбрано элементов: {entity.selected.length}
          </VirtualizedListDragContainerStyles>,
          document.getElementById("root")!
        )}
    </VirtualizedListContainerStyles>
  );
}

const VirtualizedListContainerStyles = styled("div")`
  display: flex;
  flex-direction: column;
  overflow: auto;
  position: relative;
  overscroll-behavior: contain;
  flex: 1;
`;

const VirtualizedListContentContainerStyles = styled("div")`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: sticky;
  left: 0;
  top: 0;
  min-width: 100%;
  min-height: 100%;
`;

const VirtualizedListDragContainerStyles = styled("div")`
  position: fixed;
  z-index: 10000000000000;
  border-radius: 8px;
  padding: 4px 8px;
  background: rgb(255, 255, 255);
  border: 1px solid #d7d8d8;
`;

export default observer(VirtualizedList);
