import React from "react";
import { Routes, Route } from "react-router-dom";

import AppPage from "pages/AppPage";
import DocPage from "pages/PdfDocPage";
import DocRefPage from "pages/PdfDocRefPage";
import DocRegPage from "pages/PdfDocRegPage";
import DocExportPage from "pages/PdfDocExportPage";
import SurveyPdfPage from "pages/PdfSurveyPage";
import RegistryPdfPage from "pages/PdfRegistryPage";
import Stages from "components/Stages";

function AppRouter() {
  return (
    <Routes>
      <Route exact path={process.env.PUBLIC_URL + "/"} element={<AppPage />} />
      <Route exact path={process.env.PUBLIC_URL + "/:registry"} element={<AppPage />} />
      <Route exact path={process.env.PUBLIC_URL + "/:registry/:widget/:row"} element={<AppPage />} />
      <Route exact path={process.env.PUBLIC_URL + "/:registry/:widget/:row/:version"} element={<AppPage />} />
      <Route exact path={process.env.PUBLIC_URL + "/pdf/registry"} element={<RegistryPdfPage />} />
      <Route exact path={process.env.PUBLIC_URL + "/pdf/survey"} element={<SurveyPdfPage />} />
      <Route exact path={process.env.PUBLIC_URL + "/pdf/documentation"} element={<DocPage />} />
      <Route exact path={process.env.PUBLIC_URL + "/pdf/documentationRef"} element={<DocRefPage />} />
      <Route exact path={process.env.PUBLIC_URL + "/pdf/documentationReg"} element={<DocRegPage />} />
      <Route exact path={process.env.PUBLIC_URL + "/pdf/documentation/export"} element={<DocExportPage />} />
      <Route exact path={process.env.PUBLIC_URL + "/stages"} element={<Stages />} />
    </Routes>
  );
}

export default React.memo(AppRouter);
