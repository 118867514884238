import React from "react";
import dayjs from "dayjs";
import { Menu } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { LocalizationProvider, PickersActionBar, ruRU, StaticDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import styled from "styled-components";
import ru from "dayjs/locale/ru";
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";

import { ToolButtonComponent } from "./ToolBar";

function TableEditorViewpoint({ data: [icon, handler, disabledStatus, label, key, color, text, menu, active, action], viewpoint, toolbuttonDisableStatus, tableData, selectedRows, handleReload }) {
  const theme = useTheme();

  const handleDateSelect = React.useCallback((close, value) => {
    close();
    const gmtDate = dayjs(value).tz("GMT");
    const date = dayjs(value);

    if (!date.isValid()) {
      handler({ date: null });
      return;
    }

    if (gmtDate.day() === date.day()) {
      handler({ date: date.toISOString() });
      return;
    }

    handler({ date: date.add(24 - gmtDate.hour(), "hours").toISOString() });
  }, [handler]);

  return (
    <PopupState variant="popover" popupId="demo-popup-viewpoint">
      {(popupState) => (
        <>
          <ButtonContainer {...bindTrigger(popupState)}>
            <ToolButtonComponent
              icon={icon} 
              id={action}
              handler={popupState.open}
              enabled={async () => await toolbuttonDisableStatus(disabledStatus)} 
              color={color} 
              active={active}
              tooltip={text} 
            />
          </ButtonContainer>
          <Menu
            {...bindMenu(popupState)}
            autoFocus={false}
            style={theme.typography.text}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <LocalizationProvider localeText={ruRU.components.MuiLocalizationProvider.defaultProps.localeText} adapterLocale={ru} dateAdapter={AdapterDayjs}>
              <StaticDatePicker 
                defaultValue={viewpoint ? dayjs(viewpoint) : null}
                slots={{ actionBar: PickersActionBar }}
                slotProps={{ actionBar: { actions: ["clear", "accept"] }, toolbar: { toolbarFormat: "DD MMMM YYYY" } }} 
                onAccept={handleDateSelect.bind(null, popupState.close)} 
              />
            </LocalizationProvider>
          </Menu>
        </>
      )}
    </PopupState>
  )
}

const ButtonContainer = styled("div")`
  button {
    margin: 0;
  }
`;

export default React.memo(TableEditorViewpoint);
