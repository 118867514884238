import { action, computed, makeObservable, observable } from "mobx";

import { VirtualizedListItemInterface } from "../";

import { findVirtualizedListItem, isNullOrUndefined, virtualizedListDataWithChildren, virtualizedListItemParents } from "./utils";

export abstract class AbstractVirtualizedListEntity<DataType extends VirtualizedListItemInterface> {
  constructor() {
    makeObservable(this);
  }

  containerElement: HTMLDivElement | null = null;

  nesting = false;
  dragAndDrop = false;
  dropOnlyToFolders = true;

  @observable selected: (number | string)[] = [];
  @observable startIndex = 0;
  @observable endIndex = 0;

  @observable dragging = false;
  @observable dragX = 0;
  @observable dragY = 0;
  @observable.shallow dropItem: DataType | null = null;

  @observable.shallow data: DataType[] = [];
  @observable.shallow nestingDepth: Record<number | string, number> = {};
  @observable.shallow nestingParents: Record<number | string, number | string> = {};

  @computed private get renderRowsCount() {
    if (!this.containerElement) return 0;
    const maxElements = Math.max(this.dataWithNestingElements.length, this.data.length, 0);
    return Math.min(maxElements, Math.ceil(this.containerElement.offsetHeight / 24) - 1);
  }

  @computed get selectedData() {
    return this.selected.map((id) => this.dataWithNestingElements.find((item) => item.id === id)!);
  }

  @computed get dataWithNestingElements() {
    return virtualizedListDataWithChildren(this, this.data);
  }

  @computed get visibleDataWithNestingElements() {
    return this.dataWithNestingElements.slice(this.startIndex, this.endIndex);
  }

  @action.bound updateContainerRef(container: HTMLDivElement) {
    this.containerElement = container;
    this.startIndex = 0;
    this.endIndex = this.renderRowsCount;
  }

  @action.bound updateSelected(selected: (string | number)[]) {
    this.selected = selected;
  }

  @action.bound updateData(data: DataType[]) {
    this.data = data;
    this.startIndex = 0;
    this.endIndex = this.renderRowsCount;
  }

  @action.bound onListScroll(index: number) {
    const maxElements = Math.max(this.dataWithNestingElements.length, this.data.length, 0);

    const newStartIndex = Math.max(0, Math.min(index, maxElements));
    if (this.startIndex === newStartIndex) return;

    this.startIndex = newStartIndex;
    this.endIndex = Math.min(this.startIndex + this.renderRowsCount, maxElements);
  }

  @action.bound onItemClick(item: DataType) {
    const isSelected = this.selected.includes(item.id);

    if (!isSelected) {
      this.selected = [...this.selected, item.id];
      return;
    }

    this.selected = this.selected.filter((id) => id !== item.id);
  }

  @action.bound async expandFolder(item: DataType) {
    if (!this.isFolder(item)) return;

    const children = await this.onFolderExpand(item);

    const itemParent = this.nestingParents[item.id];
    const itemDepth = this.nestingDepth[itemParent] || 0;

    this.nestingDepth[item.id] = itemDepth + 1;
    children.forEach((child) => (this.nestingParents[child.id] = item.id));

    const maxElements = Math.max(this.dataWithNestingElements.length, this.data.length, 0);
    this.endIndex = Math.min(this.startIndex + this.renderRowsCount, maxElements);
  }

  @action.bound collapseFolder(item: DataType) {
    if (!this.isFolder(item) || !this.isFolderExpanded(item)) return;

    const children = this.folderChildren(item);

    children.forEach(this.collapseFolder);
    children.forEach((child) => delete this.nestingParents[child.id]);

    delete this.nestingDepth[item.id];
    this.endIndex = this.renderRowsCount;
    this.selected = this.selected.filter((id) => !children.some((i) => i.id === id));
  }

  @action.bound startDrag() {
    if (!this.dragAndDrop) return;

    const parents = this.selected.map((id) => this.nestingParents[id]);
    if (new Set(parents).size > 1) return;

    this.dropItem = null;
    this.dragging = true;
  }

  @action.bound async stopDrag() {
    this.dragging = false;
    if (!this.dropItem) return;

    const selected = this.selected;
    const selectedData = this.selectedData;
    const targetItem = findVirtualizedListItem(this, this.data, this.dropItem.id)!;

    const result = await this.onItemsMove();

    if (result) {
      selectedData.forEach(this.collapseFolder);
      selected.forEach((id) => (this.nestingParents[id] = targetItem.id));
      if (!this.isFolderExpanded(targetItem)) this.selected = [];
    }

    this.dropItem = null;
  }

  @action.bound updateDragPositions(xPos: number, yPos: number) {
    this.dragX = xPos;
    this.dragY = yPos;
  }

  @action.bound onDropItemEnter(item: DataType) {
    if (!this.dragging) return;

    this.dropItem = null;
    if (this.dropOnlyToFolders && !this.isFolder(item)) return;

    const isSelectedItem = this.selected.includes(item.id);
    if (isSelectedItem) return;

    const targetParents = virtualizedListItemParents(this, item);
    const foundSelectedParent = targetParents.some((id) => this.selected.includes(id));
    if (foundSelectedParent) return;

    const isSelectedItemsParent = this.nestingParents[this.selected[0]] === item.id;
    if (isSelectedItemsParent) return;

    this.dropItem = item;
  }

  @action.bound onDropItemLeave() {
    this.dropItem = null;
  }

  canDropItem(item: DataType) {
    if (!this.dropItem) return false;
    return this.dropItem.id === item.id;
  }

  isFolderExpanded(item: DataType) {
    return !isNullOrUndefined(this.nestingDepth[item.id]);
  }

  isSelected(item: DataType) {
    return this.selected.includes(item.id);
  }

  itemDepth(item: DataType) {
    const itemParent = this.nestingParents[item.id];
    if (isNullOrUndefined(itemParent)) return 0;

    const parentDepth = this.nestingDepth[itemParent];
    return parentDepth;
  }

  abstract onItemsMove(): Promise<boolean>;
  abstract isFolder(item: DataType): boolean;
  abstract isFolderEmpty(item: DataType): boolean;
  abstract folderChildren(item: DataType): DataType[];
  abstract onFolderExpand(item: DataType): Promise<DataType[]>;
}
