import React from "react";
import styled from "styled-components";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import { Button, Tab, Tabs } from "@mui/material";

import { ActionDialog, ContourBackdrop } from "shared/ui";
import { fDateTime, fSelect, fText } from "shared/ui/Editors";
import Textarea from "components/itemDialog/components/Textarea";

function Comment({ onOk, reload, title, row, registry }) {
  const { enqueueSnackbar } = useSnackbar();

  const api = useSelector((state) => state.API);
  
  const [tab, setTab] = React.useState(0);
  const [data, setData] = React.useState({});
  const [linked, setLinked] = React.useState(null);
  const [statuses, setStatuses] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [closeComment, setCloseComment] = React.useState(false);

  React.useEffect(() => {
    Promise.all([
      row.comment ? api.call("comment/load", { code: row.comment }) : Promise.resolve({}),
      api.call("registries/values", { code: "comment", field: "status" }),
      registry.object_code === "indicator" ? api.call("comment/linked", { code: row.code }) : Promise.resolve(null),
    ])
    .then(([comment, statuses, linked]) => {
        if (!comment || comment?.error || !Array.isArray(statuses)) {
          setCloseComment(true);
          return;
        }

        const data = { ...comment };
        if (typeof data.status === "number") data.status = statuses.find(({ code }) => code === data.status);
        if (!data.status) data.status = statuses[0];
        
        setData(data);
        setStatuses(statuses);
        if (linked && !linked.error) setLinked(linked)
      });
  }, [api, row, registry]);

  React.useEffect(() => {
    if (!onOk || !closeComment) return;
    onOk();
  }, [closeComment, onOk]);

  const handleTabChange = React.useCallback((event, index) => setTab(index), []);

  const handleChange = React.useCallback(
    (key, event, fieldValue) => {
      const value = event?.target?.value || fieldValue;
      const newData = { ...data };
      newData[key] = value;
      setData(newData);
    },
    [data]
  );

  const handleCommentChange = React.useCallback((event) => {
    const value = event?.target?.value;
    const newData = { ...data };
    newData[tab === 0 ? "text_request" : "text_response"] = value;
    setData(newData);
  }, [tab, data]);

  const inputProps = React.useMemo(() => ({
    disabled: false,
    fullWidth: true,
    style: "compact",
    onChange: (key, event, value) => handleChange(key, event, value),
    value: data,
  }), [data, handleChange]);
  
  const handleSave = React.useCallback(async () => {
    if (!data.status?.code) {
      enqueueSnackbar("Статус не выбран", { variant: "error" });
      return;
    }
    
    const payload = {
      id: row.id,
      object_code: registry.code,
      object_class: registry.object_class,
      status: data.status.code,
      text_request: data.text_request,
      text_response: data.text_response,
    };

    setLoading(true);
    const response = await api.call("comment/save", payload);
    
    setLoading(false);
    if (!response || response?.error || response?.ok === false) {
      enqueueSnackbar("Ошибка", { variant: "error" });
      return;
    }

    enqueueSnackbar("Готово", { variant: "success" });
    reload();
    onOk();
  }, [api, reload, data, enqueueSnackbar, row, onOk, registry]);

  if (!statuses || loading) return <ContourBackdrop />;

  const linkedComments = Object.entries(linked || {});

  return (
    <ActionDialog contentStyle={{ padding: 0 }} onClose={onOk} open title={title}>
      <Container>
        <Tabs value={tab} onChange={handleTabChange}>
          <Tab label="Запрос" />
          <Tab label="Ответ" />
          {registry.object_code === "indicator" && <Tab label="Статистика" />}
        </Tabs>
          <Content>
            {tab === 0 && (
              <>
                <Textarea rows={5} title="Запрос" value={data.text_request} onChange={handleCommentChange} />
                {fText(inputProps, "Автор", "login_request", null, { disabled: true })}
                {fDateTime(inputProps, "Дата", "change_date_request", null, { disabled: true })}
              </>
            )}
            {tab === 1 && (
              <>
                <Textarea rows={5} title="Ответ" value={data.text_response} onChange={handleCommentChange} />
                {fText(inputProps, "Автор", "login_response", null, { disabled: true })}
                {fDateTime(inputProps, "Дата", "change_date_response", null, { disabled: true })}
              </>
            )}
            {tab !== 2 && ( 
              <ButtonContainer>
                {fSelect(inputProps, "Статус *", "status", null, {
                  select: statuses,
                  optionNameField: "value",
                  optionIdField: "code",
                  disableClean: true,
                })}
                <Button onClick={handleSave} variant="contained">Отправить</Button>
              </ButtonContainer>
            )}
            {tab === 2 && (
              linkedComments.length > 0 ? (
                linkedComments.map(([key, value]) => {
                  const title = statuses.find(({ code }) => code.toString() === key.toString());
                  return fText(inputProps, title?.value || key, () => value, null, { disabled: true }, key)
                })
              ) : (
                <div>Нет связанных комментариев</div>
              )
            )}
          </Content>
      </Container>
    </ActionDialog>
  );
}

const Container = styled("div")`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  max-height: 100%;
`;

const Content = styled("div")`
  display: flex;
  flex-direction: column;
  padding: 16px;
  min-height: 292px;
`;

const ButtonContainer = styled("div")`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: auto;
  padding-right: 0.25em;
`;

export default React.memo(Comment);
