import React from "react";
import styled from "styled-components";
import cloneDeep from "lodash/cloneDeep";
import { connect, useSelector } from "react-redux";
import { useSnackbar, withSnackbar } from "notistack";

import { TooltipR } from "shared/ui/ToolBar";
import { Tabs, Tab } from "shared/ui/TabBar";
import { useForceUpdate } from "shared/utils";
import { ActionDialog, ActionDialogProperty } from "shared/ui";

import { withTheme } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import { Button, Paper } from "@mui/material";

import { editorComponent } from "./components";

const ItemDialogBase = (props) => {
  const { onClose, onOk, selfOrParentId } = props;
  const [value, setValue] = React.useState();
  const [schema_query, set_schema_query] = React.useState({});
  const [chemaData, setSchemaData] = React.useState(null);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [isChanged, setChanged] = React.useState(false);

  const api = useSelector((state) => state.API);

  const theme = useTheme();

  const { enqueueSnackbar } = useSnackbar();
  const forceUpdate = useForceUpdate();

  React.useEffect(() => {
    props.currentValue && (props.currentValue.current = value);
  }, [value]);

  React.useEffect(() => {
    if (
      !props.itemChanged ||
      !props.self ||
      props.itemChanged?.id !== props.self.current.id
    )
      return;

    switch (props.itemChanged?.type) {
      case "item_update":
        setObject({ ...props.self.current.properties }, true);
        break;
      default:
        break;
    }
  }, [props.itemChanged]);

  React.useEffect(() => {
    setObject(
      props.prop ? cloneDeep(props.prop.current?.properties) : null,
      false
    );
  }, [props.prop, props.schema]);

  const setObject = (value, update) => {
    const schema = props.schema;
    if (!schema) return;

    const schema_tabs_keys = [];
    const schema_tabs = schema.props?.reduce((tabs, i) => {
      if (props.isEdit !== true && i.tab?.onlyEditMode) {
        return tabs;
      }
      const name = i.tab?.name;
      if (!schema_tabs_keys.includes(name)) {
        schema_tabs_keys.push(name);
      }
      tabs[name] = i.tab;
      return tabs;
    }, {});

    if (!value) {
      const def = {};
      schema.props?.forEach((p) => {
        if (typeof p.value === "function") {
          p.type === "select"
            ? p.value(null, "set", def, p.default)
            : p.value("set", def, p.default);
        } else {
          def[p.value] = p.default;
        }
      });
      value = { ...def, ...schema?.init?.(), ...(props.defaultValue || {}) };
    }

    const valueFlatted = {};
    Object.keys(value).forEach((name) => {
      const v = value[name];
      if (
        v instanceof Object &&
        !(v instanceof Array) &&
        (schema.flatted || []).indexOf(name) !== -1
      ) {
        Object.keys(v).forEach((sub_name) => {
          valueFlatted[`${name}.${sub_name}`] = v[sub_name];
        });
      } else {
        valueFlatted[name] = v;
      }
    });

    const proxyRef = { proxy: null };
    const proxy = new Proxy(valueFlatted, {
      set: (object, prop, value) => {
        schema?.changed?.(proxyRef, prop, value);
        object[prop] = value;
        return true;
      },
    });
    proxyRef.proxy = proxy;

    setSchemaData({ schema, schema_tabs, schema_tabs_keys });
    setValue(proxy);
    setChanged(false);
    if (!(props.isEdit === true)) {
      // инициализация
      schema.intBeforeCreate &&
        schema.intBeforeCreate(props, selfOrParentId, proxy, () => {
          forceUpdate();
        });
    }
    if (schema.data) {
      // FIXME: set_schema_query  - это promise который может сработать когда будет выбран уже другой элемент
      schema.data(
        props,
        selfOrParentId,
        value,
        (v) => {
          v._item = props.prop;
          set_schema_query(v);
        },
        // при обновлении элемента не все его контролы должны пересоздаться,
        // используем старые данные (handleOk - например)
        update && schema_query
      );
    } else {
      set_schema_query({ _item: props.prop });
    }
  };

  const emitChanged = () => {
    setChanged(true);
    forceUpdate();
    props.onChanged?.(value);
  };

  const handleChange_v3 = (value, field, val) => {
    if (!value) return;
    value[field] = val;
    emitChanged();
    setValue(value);
  }

  const handleChange_v2 = (object, v, event, val) => {
    let value = val === null || val === undefined || val === 0 ? null : val;
    if (value === null) {
      value = event?.target?.value;
      if (value === undefined) value = null;
    }
    if (typeof v === "function") {
      v("set", object, value);
    } else {
      const props = v.split(".");
      props.reduce((prev, next, ndx, props) => {
        if (ndx === props.length - 1) {
          prev[next] = value;
        } else {
          if (!prev[next]) {
            prev[next] = {};
          }
          return prev[next];
        }
      }, object);
      console.log(Object.assign({}, object || {}));
      //object[v] = value
    }
    emitChanged();
  };

  const handleChange_v2_link = (object, v, event, val) => {
    const value = val || event.target.value || null;
    const link = value && {
      id: value.current?.id,
      name: value.current?.properties?.title,
      type: value.current?.type,
    };
    typeof v === "function" ? v("set", object, link) : (object[v] = link);
    emitChanged();
  };

  const inputProps = {
    inputProps: { style: { padding: "0.25em" } },
    style: { padding: "0.5em 0.25em" },
    variant: "outlined",
    fullWidth: true,
    size: "small",
  };
  const dataProps = {
    disabled: false,
    onChange_v2: handleChange_v2,
    value: value,
    style: "compact",
  };

  const handleOk = async () => {
    const v = Object.assign({}, value || {});

    let id =
      chemaData.schema.handleOk &&
      (await chemaData.schema.handleOk(props, selfOrParentId, v, schema_query));
    if (props.api) {
      if (props.isEdit === true) {
        const ret = await props.api.update(props.selfOrParentId, v);
        if (ret?.error === true) {
          return;
        } else {
          props.enqueueSnackbar(`Элемент '${v.title}' обновлен`, {
            variant: "success",
          });
        }
      } else {
        if (!id) {
          id = await props.api.create(selfOrParentId, props.type, v);
          // TODO: check id
          if (!id || id?.error === true) {
            return;
          } else {
            props.enqueueSnackbar(`Элемент '${v.title}' создан`, {
              variant: "success",
            });
          }
        }
      }
    }
    onOk({ v, id });
    props.isDialog && onClose();
  };

  const validate = () => {
    if (!value) return true;
    const requiredFieldsKeys = [];
    props.schema.props.forEach((schema) => {
      if (schema.type === "imageBlock") {
        schema.data.withProps.forEach(
          (schema) =>
            schema?.data?.required && requiredFieldsKeys.push([schema.value, schema.label])
        );
        return;
      }

      if (schema?.type === "dwh_object") {
        requiredFieldsKeys.push([schema.value + "__code", schema.label])
        return;
      }

      if (schema?.type === "Registry") return;
      schema?.data?.required && requiredFieldsKeys.push([schema.value, schema.label]);
    });

    const foundInvalidField = requiredFieldsKeys.find(
      ([key]) => {
        if (value[key] === undefined || value[key] === null) return true;
        if (key.includes("phone")) {
          if (!value[key].startsWith("+7")) return true;
          return value[key].replaceAll(/\D/g, "").length !== 11;
        }
        if (key.includes("mail")) {
          const [firstPart, secondPart] = value[key].split("@");
          if (!firstPart || !secondPart) return true;
          const [firstDotPart, secondDotPart] = value[key].split(".");
          return !firstDotPart || !secondDotPart;
        }
        return value[key] === "";
      }
    );

    if (foundInvalidField) {
      let additionalText = "";
      if (foundInvalidField[0].includes("phone")) additionalText = ", поле должно начинаться на \"+7\" и содержать 11 цифр";
      enqueueSnackbar(`Заполните поле "${foundInvalidField[1]}"${additionalText}`, { variant: "error" });
    }
    return !!foundInvalidField;
  };

  const handleChange = (event, id) => {
    setCurrentPage(id);
  };

  const workAreaTabStyle = {
    overflow: "none",
    margin: 0,
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    position: "relative",
  };

  const size = "1.85em";
  const icon_style = (current) => ({
    display: "block",
    objectFit: "contain",
    margin: "0.25em 0.5em",
    cursor: "pointer",
    maxWidth: size,
    maxHeight: size,
    minWidth: size,
    minHeight: size,
    width: "auto",
    height: "auto",
    fill: current ? theme.palette.menu.icon : theme.palette.text.disabled,
  });

  const tab = (name) => {
    const schema = chemaData.schema.props.filter(
      (i) => (!i.tab || i.tab.name === name) && (!i.visible || i.visible(value, schema_query))
    );

    return schema.map((i, ndx) =>
      editorComponent(
        i,
        ndx,
        inputProps,
        dataProps,
        handleChange_v2,
        handleChange_v2_link,
        handleChange_v3,
        value,
        schema_query,
        emitChanged,
        theme,
        props,
        api,
        schema.length === 2
      )
    );
  }

  const tabs = chemaData && chemaData.schema_tabs_keys.length > 1 && (
    <Tabs
      value={currentPage}
      onChange={handleChange}
      variant="scrollable"
      scrollButtons="auto"
      style={{ padding: 0 }}
    >
      {value &&
        chemaData.schema_tabs_keys.map((name, ndx) => {
          return (
            <Tab
              icon={
                <TooltipR text={name}>
                  <div style={{ display: "flex", alignItems: "center", margin: "0.5em" }}>
                    {name}
                  </div>
                </TooltipR>
              }
              id={ndx}
              key={ndx}
            />
          );
        })}
    </Tabs>
  );

  const Type =
    props.isDialog === false
      ? ActionDialogProperty
      : props.isEmbeded
      ? "div"
      : ActionDialog;
  const typeProps =
    Type === "div"
      ? { style: { display: "flex", flexGrow: 1 } }
      : {
          item: props.self,
          open: true,
          tabs,
          validate,
          titleIcon: props.titleIcon,
          minHeight: props.minHeight,
          maxWidth: props.maxWidth,
          width: props.width,
          customActions: props.customActions,
          withAction: props.withAction || true,
          confirmationText: props.confirmationText,
          withConfirmation: props.withConfirmation,
          onOk: (isChanged || !props.isEdit) && handleOk,
          title: value?.title || props.title || "Создать",
        };

  return (
    <Type {...typeProps} style={props.isEmbeded && { display: "flex", flex: 1, flexDirection: "column" }} onClose={onClose}>
      {value &&
        chemaData.schema_tabs_keys.map((name, ndx) => {
          const isHidden = currentPage !== ndx; 
          return (
            <Paper
              key={ndx || 7}
              hidden={isHidden}
              square
              elevation={0}
              style={workAreaTabStyle}
            >
              {!isHidden && tab(name)}
            </Paper>
          )
        })}
        {props.isEmbeded && props.onOk && (
          <ButtonContainer>
            <Button disabled={!props.prop?.current?.properties} onClick={handleOk} variant="contained">
              Сохранить
            </Button>
          </ButtonContainer>
        )}
    </Type>
  );
};

const ButtonContainer = styled("div")`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex: 1;
`;

const mapStateToProps = function (state) {
  return {
    db: state.databaseAPI,
    itemChanged: state.itemChanged,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default withTheme(
  withSnackbar(connect(mapStateToProps, mapDispatchToProps)(ItemDialogBase))
);
