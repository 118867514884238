import React from "react";
import IconButton from "@mui/material/Button";
import SvgIcon from "@mui/material/SvgIcon";

import { ReactComponent as IconEmpty } from "@mdi/svg/svg/select-all.svg";

import Menu from "@mui/material/Menu";
import Paper from "@mui/material/Paper";
import CardContent from "@mui/material/CardContent";

import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

import withStyles from "@mui/styles/withStyles";
import { useTheme } from "@mui/material/styles";

import Tooltip from "@mui/material/Tooltip";

const StyledButton = withStyles(
  (theme) => ({
    root: {
      padding: "0 0.5em",
      margin: "0 4px 0 4px",
      width: "auto",
      height: "100%",
      color: "inherit",
      border: "none",
      minWidth: "auto",
      textTransform: "none",
      display: "flex",
      alignItems: "center",
      gap: "0.25em",
      minHeight: "24px !important",
      whiteSpace: "nowrap",

      "&.Mui-disabled": {
        padding: "0 0.5em",
        width: "auto",
        height: "auto",
        border: "none",
        minWidth: "auto",
        textTransform: "none",
      },

      "&:hover": {
        background: "#DCE3E5"
      },

      "& svg": {
        fill: "rgba(29, 29, 31, 0.5)",
        transition: "fill 250ms cubic-bezier(0.4, 0, 0.2, 1)",
      },

      "&:disabled svg": {
        fill: "rgba(0, 0, 0, 0.1)",
      },
    },
  }),
  { withTheme: true }
)(IconButton);

const StyledButtonGroup = withStyles(
  (theme) => ({
    root: {
      minHeight: "2.5em",
      display: "flex",
      boxShadow: "none",
      alignItems: "center",
      padding: "0.25em 0",
      borderRight: "none",
      borderLeft: "none",
      borderRadius: 0,
      borderTop: `0px solid ${theme.palette.divider}`,
      borderBottom: "none !important",
      background: `${theme.palette.toolbar.background}`,
      overflow: "hidden",
    },
  }),
  { withTheme: true }
)(Paper);

const Icon = (color, icon, size, htmlColor, additionalStyle, props, viewBox) => {
  const style = {
    ...(additionalStyle || {}),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: size || 24,
    height: size || 24,
  };
  const prop = props || [];

  if (typeof icon === "string" && icon.startsWith("<svg")) return <div dangerouslySetInnerHTML={{ __html: icon }} style={style} />;

  return htmlColor ? (
    <SvgIcon {...prop} htmlColor={htmlColor} component={icon} style={style} viewBox={viewBox} />
  ) : (
    <SvgIcon {...prop} color={color} component={icon} style={style} viewBox={viewBox} />
  );
};

const Icon512 = (color, icon, size, htmlColor, additionalStyle, props) => {
  const style = {
    ...(additionalStyle || {}),
    width: size || 32,
    height: size || 32,
  };
  const prop = props || [];
  return htmlColor ? (
    <SvgIcon {...prop} htmlColor={htmlColor} component={icon} style={style} viewBox={"0 0 512 512"} />
  ) : (
    <SvgIcon {...prop} color={color} component={icon} style={style} viewBox={"0 0 512 512"} />
  );
};

const ButtonNoBind = (key, color, icon, handler, enabled) => (
  <StyledButton key={key} fullWidth={false} onClick={handler && handler.bind(this, key)} disabled={enabled === false}>
    {Icon(enabled === false ? "disabled" : color, icon)}
  </StyledButton>
);

const Button = (color, icon, handler, size, htmlColor, text) => (
  <StyledButton fullWidth={false} onClick={handler && handler.bind(this)}>
    {icon && Icon(color, icon, size, htmlColor)}
    {text}
  </StyledButton>
);

const ToolButton = (icon, handler, enabled, tooltip, key, color, text, placement, disablePointerEvents) => (
  <TooltipR text={tooltip} key={key} placement={placement}>
    <StyledButton
      color={"primary"}
      disabled={enabled === false || enabled == null}
      fullWidth={false}
      className="toolbutton"
      onClick={handler && handler.bind(this)}
    >
      {Icon(enabled === false || enabled == null ? "disabled" : color || "action", icon || IconEmpty, null, null)}
    </StyledButton>
  </TooltipR>
);

const DictionaryFilterToolButton = (icon, handler, enabled, tooltip, key, color, text, placement, disablePointerEvents) => (
  <TooltipR text={tooltip} key={key} placement={placement}>
    <StyledButton
      color={"primary"}
      disabled={enabled === false || enabled == null}
      fullWidth={false}
      className="toolbutton"
      onClick={handler && handler.bind(this)}
    >
      {Icon(enabled === false || enabled == null ? "disabled" : color || "action", icon || IconEmpty, 20, null,)}
    </StyledButton>
  </TooltipR>
);

function ToolButtonComponent({ icon, id, handler, enabled, tooltip, color, placement, active }) {
  const [disabled, setDisabled] = React.useState(true);

  React.useLayoutEffect(() => {
    if (enabled === undefined) return;
    if (typeof enabled !== "function") {
      setDisabled(!enabled);
      return;
    }
    (async () => {
      const res = await enabled();
      setDisabled(!res);
    })();
  }, [enabled]);

  return (
    <TooltipR text={tooltip} placement={placement}>
      <StyledButton color={"primary"} id={id} disabled={disabled} fullWidth={false} className={`toolbutton ${active && "toolbutton-active"}`} onClick={handler && handler.bind(this)}>
        {Icon(disabled ? "disabled" : color || "action", icon || IconEmpty, null, null, {})}
      </StyledButton>
    </TooltipR>
  );
}

const ToolButtonIcon = (Icon, handler, enabled, tooltip, key, color, text, style) => (
  <TooltipR text={tooltip} key={key}>
    <span>
      <StyledButton
        style={style}
        color={"primary"}
        disabled={enabled === false || enabled == null}
        fullWidth={false}
        onClick={handler && handler.bind(this)}
      >
        {Icon}
        {text}
      </StyledButton>
    </span>
  </TooltipR>
);

const ToolButtonCustom = (component, handler, enabled, tooltip, key, color, text) => (
  <TooltipR text={tooltip} key={key}>
    <span>
      <StyledButton
        color={"primary"}
        disabled={enabled === false || enabled == null}
        fullWidth={false}
        onClick={handler && handler.bind(this)}
      >
        {component}
        {text}
      </StyledButton>
    </span>
  </TooltipR>
);

const ToolButtonMenu = (icon, enabled, tooltip, menuItems, theme, key) => (
  <PopupState variant="popover" popupId="demo-popup-menu" key={key}>
    {(popupState) => (
      <React.Fragment>
        <TooltipR text={tooltip}>
          <span>
            <StyledButton {...bindTrigger(popupState)} disabled={enabled === false} fullWidth={false}>
              {Icon(enabled === false || enabled == null ? "disabled" : "action", icon)}
            </StyledButton>
          </span>
        </TooltipR>
        {popupState.isOpen && (
          <Menu
            style={theme?.typography.text}
            {...bindMenu(popupState)}
            getContentAnchorEl={null}
            autoFocus={false}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            {menuItems(popupState)}
          </Menu>
        )}
      </React.Fragment>
    )}
  </PopupState>
);

const ButtonSeparator = (key) => (
  <StyledButton key={key} disabled style={{ border: "none", flexGrow: 1 }}>
    {Icon(null, IconEmpty, null, "transparent")}
  </StyledButton>
);

const _tooltip = (component, tooltip) => (
  <Tooltip title={tooltip} arrow>
    {component}
  </Tooltip>
);

const Button2 = (key, color, icon, handler) => (
  <StyledButton key={key} fullWidth={false} onClick={handler}>
    {icon}
  </StyledButton>
);

const ButtonEmpty = (key, width) => (
  <StyledButton key={key} disabled style={{ border: "none", width: width || "1em" }}>
    {Icon(null, IconEmpty, null, "transparent")}
  </StyledButton>
);

const popover = (text, theme, disablePointerEvents) => {
  const style = {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeight,
    fontSize: theme.typography.fontSize,
    lineHeight: theme.typography.lineHeight,
    letterSpacing: theme.typography.letterSpacing,
    pointerEvents: disablePointerEvents ? "none" : "auto",
  };
  return (
    <Popover
      id="popover-basic"
      style={{
        marginTop: "12px",
        background: "rgba(59, 106, 223, 1)",
        zIndex: 2000,
        borderRadius: "4px",
        padding: "8px",
        ...style,
      }}
    >
      <Popover.Content style={{ padding: "0.25em", color: "rgba(255, 255, 255, 1)" }}>{text}</Popover.Content>
    </Popover>
  );
};

const TooltipR = (props) => {
  const theme = useTheme();
  return !props.text ? (
    props.children
  ) : (
    <OverlayTrigger
      trigger={["hover", "hover"]}
      placement={props.placement || "bottom"}
      overlay={popover(props.text, theme, props.disablePointerEvents)}
    >
      {props.children}
    </OverlayTrigger>
  );
};

const CardContentStyle = (theme) => ({
  root: {
    backgroundColor: "rgba(0, 0, 0, 0.04)",
    padding: "1em",
    "&:last-child": {
      paddingBottom: "1em",
    },
  },
});
const CardContentStyled = withStyles(CardContentStyle, { withTheme: true })(CardContent);

export {
  CardContentStyled,
  ToolButton,
  DictionaryFilterToolButton,
  Icon,
  Icon512,
  Button,
  Button2,
  ButtonNoBind,
  ToolButtonComponent,
  ButtonSeparator,
  StyledButtonGroup,
  StyledButton,
  ButtonEmpty,
  _tooltip,
  TooltipR,
  ToolButtonMenu,
  ToolButtonCustom,
  ToolButtonIcon,
};
