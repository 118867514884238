/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import clsx from "clsx";
import styled from "styled-components";
import { Chip } from "@mui/material";

import TableView from "components/itemView/TableView";
import FilterList from "components/HyperGrid/DimensionCard/FilterList";
import { DimButton, PopoverCustom } from "components/HyperGrid/DimensionCard";
import DropableArea from "components/HyperGrid/DimensionCard/DropableArea";

import { ReactComponent as FAxisIcon } from "@mdi/svg/svg/focus-field.svg";
import { ReactComponent as ColumnIcon } from "@mdi/svg/svg/book-outline.svg";
import { ReactComponent as ArrowLeft } from "@mdi/svg/svg/arrow-left.svg";
import { ReactComponent as ArrowRight } from "@mdi/svg/svg/arrow-right.svg";

import { Icon } from "shared/ui/ToolBar";
import { DUPLICATE_INDENTIFICATOR } from "shared/ui/TableEditor";

const CustomChip = styled(Chip)`
  gap: 4px;
  svg {
    margin: 0 !important;
  }
`;

const FilterTagsContainer = styled("div")`
  display: flex;
  gap: 0.25em;
  padding-bottom: 0.35em;
  max-width: 100%;

  .MuiChip-root {
    height: 26px;
    padding: 0 12px;
    background-color: rgba(0, 0, 0, 0.05);
  }

  .MuiChip-label {
    font-size: 13px;
    padding: 0;
  }

  .MuiSvgIcon-root {
    width: 16px;
    height: 16px;
    margin: 0 -6px 0 4px;
  }
`;

const FilterSelectedTagsContainer = styled("div")`
  display: flex;
  gap: 0.25em;
`;

const FilterSelectedTagsResizeContainer = styled("div")`
  display: flex;
  width: 100%;
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const INITIAL_TAGS_STATE = {
  leftButtonIsHidden: true,
  leftButtonIsDisabled: true,
  rightButtonIsHidden: true,
  rightButtonIsDisabled: true,
};

const DataTableView = (props) => {
  const rtl = useSelector((state) => state.rtl);
  const [dataItem, setDataItem] = useState();
  const [isDragState, setDragState] = useState(false);
  const [updatabled] = useState({});
  const [tagsVisibility, setTagsVisibility] = useState(true);

  const [selectedTagsState, setSelectedTagsState] =
    useState(INITIAL_TAGS_STATE);
  const [selectedTagsReseizeRef, setSelectedTagsReseizeRef] = useState(null);
  const [selectedTagsRef, setSelectedTagsContainerRef] = useState(null);

  const {
    getIds,
    trDims,
    tags = [],
    api,
    isAllowEdit,
    onTagsChange,
    setRowsFn,
    onEditorOk,
    singleRowSelect,
    idInFilters,
    customSortOrder,
    onSelectedRowsChanged,
    reloadFn,
    clearSortingFn,
    recalculateFn,
    onColumnDrop,
    clearFiltersFn,
    disableColumnsDef,
    filtersFn,
    cantEditCell,
    getSelected,
    dropFn,
    reloadOnFiltersChange = true,
    hiddenColumnDrag,
    onDropHiddenColumn,
  } = props;

  const reload = () => setDataItem((dataItem) => ({ ...dataItem }));

  React.useEffect(() => reload(), [rtl]);

  const clearFilters = () => {
    trDims.forEach((d) => {
      if (d.properties.clearableFilter === false) return;
      d.properties.filter && (d.properties.filter = []);
      d.properties.textFilter && (d.properties.textFilter = {});
    });
    props?.onFiltersChange?.(filters());
    if (reloadOnFiltersChange) reload();
  };

  useEffect(() => {
    setDataItem(props.dataItem);
  }, [props.dataItem]);

  const handleScrollTagsLeft = React.useCallback(() => {
    const step = Math.floor(selectedTagsReseizeRef.offsetWidth * 0.75);
    const value =
      selectedTagsReseizeRef.scrollLeft > step
        ? selectedTagsReseizeRef.scrollLeft - step
        : 0;
    selectedTagsReseizeRef.scrollTo({
      left: value,
      behavior: "smooth",
    });

    const maxLeftScroll =
      selectedTagsRef.scrollWidth - selectedTagsReseizeRef.offsetWidth;
    const leftButtonIsDisabled = value === 0;
    const rightButtonIsDisabled = value === maxLeftScroll - 1;
    setSelectedTagsState((state) => ({
      ...state,
      leftButtonIsDisabled,
      rightButtonIsDisabled,
    }));
  }, [selectedTagsRef, selectedTagsReseizeRef]);

  const handleScrollTagsRight = React.useCallback(() => {
    const step = Math.floor(selectedTagsReseizeRef.offsetWidth * 0.75);
    const maxLeftScroll =
      selectedTagsRef.scrollWidth - selectedTagsReseizeRef.offsetWidth;
    const leftScrollSpace =
      maxLeftScroll - 1 - selectedTagsReseizeRef.scrollLeft;
    const value =
      leftScrollSpace > step
        ? selectedTagsReseizeRef.scrollLeft + step
        : maxLeftScroll;
    selectedTagsReseizeRef.scrollTo({
      left: value,
      behavior: "smooth",
    });

    const leftButtonIsDisabled = value === 0;
    const rightButtonIsDisabled = value === maxLeftScroll;
    setSelectedTagsState((state) => ({
      ...state,
      leftButtonIsDisabled,
      rightButtonIsDisabled,
    }));
  }, [selectedTagsRef, selectedTagsReseizeRef]);

  useEffect(() => {
    if (!selectedTagsRef) return;

    const resizeObserver = new ResizeObserver(() => {
      const haveHiddenContent =
        selectedTagsRef.scrollWidth - selectedTagsReseizeRef.offsetWidth > 0;
      setSelectedTagsState(
        haveHiddenContent
          ? {
              leftButtonIsHidden: false,
              leftButtonIsDisabled: true,
              rightButtonIsHidden: false,
              rightButtonIsDisabled: false,
            }
          : INITIAL_TAGS_STATE
      );
    });

    const unobserve = () => resizeObserver.unobserve(selectedTagsRef);
    resizeObserver.observe(selectedTagsRef);
    const unobserveResize = () =>
      resizeObserver.unobserve(selectedTagsReseizeRef);
    resizeObserver.observe(selectedTagsReseizeRef);

    const haveHiddenContent =
      selectedTagsRef.scrollWidth - selectedTagsReseizeRef.offsetWidth > 0;
    setSelectedTagsState(
      haveHiddenContent
        ? {
            leftButtonIsHidden: false,
            leftButtonIsDisabled: true,
            rightButtonIsHidden: false,
            rightButtonIsDisabled: false,
          }
        : INITIAL_TAGS_STATE
    );

    return () => {
      unobserve();
      unobserveResize();
    };
  }, [selectedTagsRef, selectedTagsReseizeRef]);

  const handleSortOrderClear = () => {
    trDims.forEach(trDim => trDim.properties.sortOrder = "none");
    if (customSortOrder?.current) customSortOrder.current = [];
    props?.onOrderChange([])
    reload();
  }

  const handleSortOrderChanged = (dim, order) => {
    if (props.customOrderChange) {
      const result = props.customOrderChange(dim, order);
      if (result) reload();
      return;
    }

    const dimName = dim.properties.name.split(DUPLICATE_INDENTIFICATOR)[0]
    dim.properties.sortOrder = order;
    reload();
    
    if (Array.isArray(customSortOrder?.current)) {
      const sortIndex = customSortOrder.current.findIndex(([itemCode]) => itemCode === dimName);
      if (sortIndex !== -1) customSortOrder.current.splice(sortIndex, 1);
      if (order !== "none") customSortOrder.current.push([dimName, order]);
    }

    if (!props.onOrderChange) return;
    if (Array.isArray(customSortOrder?.current)) {
      props.onOrderChange(customSortOrder.current);
      return;
    }

    const sortOrder = [];

    trDims
      .filter((d) => ["data", "fixed"].includes(d.properties.axis))
      .filter((d) => {
        const name = d.properties.name.split(DUPLICATE_INDENTIFICATOR)[0];
        if (dimName === name) d.properties.sortOrder = order;
        return ["DESC", "ASC"].includes(d.properties.sortOrder)
      })
      .forEach((d) => {
        const name = d.properties.name.split(DUPLICATE_INDENTIFICATOR)[0];
        const isAlreadyExist = sortOrder.find(([itemCode]) => itemCode === name);
        if (isAlreadyExist) return;
        sortOrder.push([name, d.properties.sortOrder]);
      });

    props.onOrderChange(sortOrder);
  };

  const handleFiltersChanged = () => {
    props.onFiltersChange?.(filters(), trDims);
    if (reloadOnFiltersChange) reload();
  };

  const removeFromAxis = (dim) => {
    const { axis, position } = dim.properties;
    trDims.forEach((d) => {
      if (d.properties.axis === axis)
        if (d.properties.position > position) --d.properties.position;
    });
    dim.properties.axis = "none";
    dim.properties.position = -1;
  };

  const appendToAxis = (dim, axis) => {
    const last =
      trDims
        .filter((d) => d.properties.axis === axis)
        .reduce((max, d) => Math.max(max, d.properties.position), 0) + 1;
    dim.properties.axis = axis;
    dim.properties.position = last;
  };

  const prependToAxis = (dim, axis) => {
    trDims
      .filter((d) => d.properties.axis === axis)
      .forEach((d) => d.properties.position++);
    dim.properties.axis = axis;
    dim.properties.position = 0;
  };

  const insertToAxis = (dim, axis, position) => {
    trDims
      .filter((d) => d.properties.axis === axis)
      .forEach(
        (d) => d.properties.position >= position && d.properties.position++
      );
    dim.properties.axis = axis;
    dim.properties.position = position;
  };

  const handleFixedChanged = (dim, isFixed) => {
    removeFromAxis(dim);
    isFixed ? appendToAxis(dim, "fixed") : prependToAxis(dim, "data");
    reload();
  };

  const handleHover = (id, pos) => {
    console.log("handleHover: ", id);
    const h = { id, pos };
    Object.values(updatabled).forEach((i) => {
      // обновляем только старый и новый hover
      if (i.id === i.hoverId.id || i.id === h.id) i.setHoverId?.(h);
    });
  };

  const handleDrop = (dim, dimTo, isBefore) => {
    const h = { id: null };
    Object.values(updatabled).forEach((i) => {
      // обновляем только старый hover
      if (i.id === i.hoverId.id) i.setHoverId?.(h);
    });

    setDragState(false);

    const foundSourceDimIndex = trDims.findIndex(({ id }) => dim?.id === id);
    const foundTargetDimIndex = trDims.findIndex(({ id }) => dimTo?.id === id);

    if (dimTo?.dropableArea && foundSourceDimIndex !== -1) {
      const newDims = [...trDims];
      newDims[foundSourceDimIndex].properties.axis = "filter";
      onColumnDrop?.(newDims);
      return;
    }

    if (foundSourceDimIndex !== -1 && foundTargetDimIndex !== -1) {
      console.log("handleDrop: ", dim, dimTo, isBefore, foundSourceDimIndex, foundTargetDimIndex);
      const newDims = [...trDims];
      dim.properties.axis = dimTo.properties.axis;
      newDims.splice(foundSourceDimIndex, 1);
      newDims.splice(foundTargetDimIndex + (foundSourceDimIndex > foundTargetDimIndex ? 1 : 0) + (isBefore ? -1 : 0), 0, dim);
      onColumnDrop?.(newDims);
    } else {
      console.log("STOP DRAG");
    }
  };

  const handleStartDrag = (id) => {
    console.log("START DRAG");
    // delay for display placeholders
    requestAnimationFrame(() => setDragState(true));
  };

  const dimProps = {
    api,
    disableMoveFiltersButton: props.disableMoveFiltersButton,
    disableSearchAndReplaceButton: props.disableSearchAndReplaceButton,
    icon: props.disableTableColumnHeaderIcons ? "" : null,
    onApplyFilter: handleFiltersChanged,
    isDragState: isDragState,
    onHover: handleHover,
    onDrop: handleDrop,
    onStartDrag: handleStartDrag,
    onChangeOrder: handleSortOrderChanged,
    onChangeFixed: handleFixedChanged,
    onDropHiddenColumn,
    updatabled,
  };

  const HAxisContent = (args) => {
    const { classes, keys, style, head, bindData } = args;
    if (!head)
      return (
        <div
          className={clsx(classes.common, classes.haxis)}
          {...bindData}
          id={keys}
          key={keys}
          onMouseOver={() => {}}
          onMouseOut={() => {}}
          style={style}
        ></div>
      );

    const dim = head.data;
    
    // if (head?.data?.properties?.customHeader) {
    //   const Component = head?.data?.properties?.customHeader;
    //   return (
    //     <div
    //       className={clsx(classes.common, classes.haxis)}
    //       {...bindData}
    //       id={keys}
    //       key={keys}
    //       style={dim ? { ...style, ...{ padding: 0, background: "rgba(130, 186, 255, 0.04)" } } : style}
    //     >
    //       <Component reload={reload} column={dim} />
    //     </div>
    //   )
    // }

    return (
      <div
        className={clsx(classes.common, classes.haxis)}
        {...bindData}
        id={keys}
        key={keys}
        onMouseOver={() => {}}
        onMouseOut={() => {}}
        style={dim ? { ...style, padding: 0, ...(dim.styles || {}) } : style}
      >
        {dim ? (
          <DimButton
            allowChangeSortOrder={true}
            additionalFilters={props.additionalFilters}
            icon={dim.properties.iconComponent || ColumnIcon}
            dim={dim}
            id={`1d${dim.id}`}
            key={dim.id}
            {...dimProps}
            full={true}
            style={{ width: "100%", border: "none" }}
          />
        ) : (
          <div className={classes.hdiv}>
            <span className={classes.ispanBig}>
              {head.title || head.name || "-"}
            </span>
            {head.title && (
              <span className={classes.ispanSmall}>{head.name || "-"}</span>
            )}
          </div>
        )}
      </div>
    );
  };

  const onColumnsDef = async (columns) => {
    if (disableColumnsDef) return;
    columns.forEach(
      (c) => (c.data = trDims.find((d) => d.properties.name === c.name))
    );
  };

  const handleTagRemove = (tagCode) => {
    const newTags = [...tags];
    const foundTagIndex = newTags.findIndex((tag) => tag.code === tagCode);
    if (foundTagIndex === -1) return;
    newTags[foundTagIndex].selected = false;
    onTagsChange?.(newTags);
  };

  const handleTagChange = (tagCodes) => {
    const newTags = tags.map((tag) => {
      const isSelected = tagCodes.includes(tag.code);
      return { ...tag, selected: isSelected };
    });
    onTagsChange?.(newTags);
  };

  const filters = () => {
    const namedDims = trDims
      .filter((d) => true)
      .filter((d) => d.properties.name != null);
    const filter = [];
    for (let d of namedDims) {
      const id = idInFilters ? d.id : d.properties.name;
      switch (d.properties.type) {
        case "Boolean": {
          const { value } = d.properties.textFilter || {};
          props.additionalFilters?.applyFilters?.(d, filter);
          if (value === undefined || value === null) break;
          filter.push([
            null,
            id,
            "=",
            value?.id ? true : false,
            false,
            null,
            "AND",
          ]);
          break;
        }
        case "Number":
        case "IFrameLink":
        case "ObjectArray":
        case "Memo": {
          const { text, cs, operator } = d.properties.textFilter || {};
          props.additionalFilters?.applyFilters?.(d, filter);
          if (operator === "=" && !text) break;
          
          switch (operator) {
            case null:
            case undefined:
            case "ANY":
              break;
            case "=":
            case "<>":
            case ">":
            case ">=":
            case "<":
            case "<=":
            case "IS NULL":
            case "IS NOT NULL":
              filter.push([
                null,
                id,
                operator,
                text,
                !cs,
                null,
                "AND",
              ]);
              break;
            case "LIKE":
              filter.push([
                null,
                id,
                "LIKE",
                "%" + text + "%",
                !cs,
                null,
                "AND",
              ]);
              break;
            case "NOT LIKE":
              filter.push([
                null,
                id,
                "NOT LIKE",
                "%" + text + "%",
                !cs,
                null,
                "AND",
              ]);
              break;
            case "% LIKE":
              filter.push([
                null,
                id,
                "LIKE",
                "%" + text,
                !cs,
                null,
                "AND",
              ]);
              break;
            case "NOT % LIKE":
              filter.push([
                null,
                id,
                "NOT LIKE",
                "%" + text,
                !cs,
                null,
                "AND",
              ]);
              break;
            case "LIKE %":
              filter.push([
                null,
                id,
                "LIKE",
                text + "%",
                !cs,
                null,
                "AND",
              ]);
              break;
            case "NOT LIKE %":
              filter.push([
                null,
                id,
                "NOT LIKE",
                text + "%",
                !cs,
                null,
                "AND",
              ]);
              break;
            default:
              break;
          }
          break;
        }
        case "Date": 
        case "DateTime": {
          const { start, end, operator } = d.properties.textFilter || {};
          const startValue = start?.__proto__ === Date.prototype ? start.toISOString() : start;
          const endValue = end?.__proto__ === Date.prototype ? end.toISOString() : end;

          const endValueDateFormat = new Date(startValue);
          const endValueDateTimeFormat = new Date(startValue); 
          const noTime = typeof startValue === "string" && startValue.endsWith("00:00:00.000Z");

          endValueDateFormat.setHours(endValueDateFormat.getHours() + 23); 
          endValueDateFormat.setMinutes(endValueDateFormat.getMinutes() + 59); 
          endValueDateFormat.setSeconds(endValueDateFormat.getSeconds() + 59)
          endValueDateTimeFormat.setSeconds(endValueDateTimeFormat.getSeconds() + 59);

          const isNullOperator = operator === "IS NULL" || operator === "IS NOT NULL";
          if (!isNullOperator && (startValue === undefined || startValue === null)) break;
          
          switch (operator) {
            case null:
            case undefined:
            case "ANY":
              break;
            case ">":
            case "<":
            case ">=":
            case "<=":
              filter.push([null, id, operator, [startValue], false, null, "AND"]);
              break;
            case "<>":
              if (noTime) {
                filter.push(
                  [null, id, "<", [startValue], false, null, "OR"],
                  [null, id, ">", [endValueDateFormat.toISOString()], false, null, "AND"],
                );
              } else {
                filter.push(
                  [null, id, "<", [startValue], false, null, "OR"],
                  [null, id, ">", [endValueDateTimeFormat.toISOString()], false, null, "AND"],
                );
              }
              break;
            case "=":
              if (noTime) {
                filter.push(
                  [null, id, ">=", [startValue], false, null, "AND"],
                  [null, id, "<=", [endValueDateFormat.toISOString()], false, null, "AND"],
                );
              } else {
                filter.push(
                  [null, id, ">=", [startValue], false, null, "AND"],
                  [null, id, "<=", [endValueDateTimeFormat.toISOString()], false, null, "AND"],
                );
              }
              break;
            case "IS NULL":
            case "IS NOT NULL":
              filter.push([
                null,
                id,
                operator,
                [null],
                false,
                null,
                "AND",
              ]);
              break;
            case "BETWEEN":
              filter.push([
                null,
                id,
                ">",
                [startValue],
                false,
                null,
                "AND",
              ]);
              filter.push([
                null,
                id,
                "<",
                [endValue],
                false,
                null,
                "AND",
              ]);
              break;
            case "NOT BETWEEN":
              filter.push([
                null,
                id,
                "<",
                [startValue],
                false,
                null,
                "OR",
              ]);
              filter.push([
                null,
                id,
                ">",
                [endValue],
                false,
                null,
                "AND",
              ]);
              break;
            default:
              break;
          }
          break;
        }
        case "Dictionary": {
          const f = getSelected(d.id, true);
          if (f?.length > 0) {
            // [ null, "context_id", "IN",[ 37, 150, 154, 78 ], false, ")", "AND" ]
            filter.push([null, id, "IN", f, false, null, "AND"]);
          }
          break;
        }
        default:
          break;
      }
    }
    return filter;
  };

  reloadFn && (reloadFn.current = reload);
  clearFiltersFn && (clearFiltersFn.current = clearFilters);
  clearSortingFn && (clearSortingFn.current = handleSortOrderClear);
  filtersFn && (filtersFn.current = filters);
  dropFn && (dropFn.current = handleDrop);

  const selectedTags = tags.filter((tag) => tag.selected);

  const staticDims = trDims
    .filter((i) => i.properties.axis === "static")
    .sort((l, r) => l.properties.position < r.properties.position ? -1 : 1)

  const filterDims = trDims
    .filter((i) => i.properties.axis === "filter" && !i.properties.hidden)
    .sort((l, r) => l.properties.position < r.properties.position ? -1 : 1)

  return (
    <>
      {(staticDims.length > 0 || filterDims.length > 0 || isDragState || hiddenColumnDrag) && (
        <div
          key={"filter_axis"}
          style={{ display: "flex", flexDirection: "row", margin: "0.5em", gap: "0.5em" }}
        >
          {staticDims.map((dim, ndx) => {
            return (
              <DimButton
                filterArea
                allowChangeSortOrder={false}
                icon={dim.properties.iconComponent || FAxisIcon}
                dim={dim}
                id={`1s${dim.id}`}
                key={dim.id}
                {...dimProps}
              />
            );
          })}
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              overflow: "hidden",
              marginRight: "1em",
              flexWrap: "wrap",
              alignItems: "center", 
              gap: "0.5em"
            }}
          >
            {filterDims.map((dim) => {
              return (
                <DimButton
                  allowChangeSortOrder={false}
                  filterArea
                  icon={dim.properties.iconComponent || FAxisIcon}
                  dim={dim}
                  id={`1f${dim.id}`}
                  key={dim.id}
                  {...dimProps}
                />
              );
            })}
            {filterDims.length === 0 && staticDims.length === 0 && (isDragState || hiddenColumnDrag) && (
              <DropableArea
                id="drop-area"
                {...dimProps}
              />
            )}
          </div>
        </div>
      )}
      {tags.length !== 0 && (
        <FilterTagsContainer>
          <div>
            <PopoverCustom
              {...props}
              dim={{
                id: "tags",
                properties: {
                  title: "Добавить",
                  name: "tags",
                  type: "Dictionary",
                  table: "tags",
                  axis: "filter",
                  position: 0,
                  sortOrder: "none",
                  filter: [],
                },
              }}
              treeData={tags.map(({ label, code, selected }) => ({
                name: () => label,
                icon: () => null,
                actual: () => true,
                noChildren: true,
                id: code,
                selected,
              }))}
              isTags
              toggleSelectedTagsVisibility={() => setTagsVisibility((v) => !v)}
              trigger={
                <CustomChip
                  style={{ backgroundColor: "transparent" }}
                  onClick={() => {}}
                  variant="outlined"
                  label={<span>Выбрать тэги</span>}
                />
              }
              Component={FilterList}
              onTagsChange={handleTagChange}
              hideTopBar
            />
          </div>
          {tagsVisibility && (
            <>
              {!selectedTagsState.leftButtonIsHidden && (
                <CustomChip
                  style={{ backgroundColor: "transparent" }}
                  variant="outlined"
                  disabled={selectedTagsState.leftButtonIsDisabled}
                  onClick={handleScrollTagsLeft}
                  label={Icon("action", ArrowLeft, 18, null, { margin: 0 })}
                />
              )}
              <FilterSelectedTagsResizeContainer
                ref={setSelectedTagsReseizeRef}
              >
                <FilterSelectedTagsContainer ref={setSelectedTagsContainerRef}>
                  {selectedTags.map(({ label, code }) => (
                    <CustomChip
                      key={code}
                      variant="outlined"
                      onDelete={() => handleTagRemove(code)}
                      label={label}
                    />
                  ))}
                </FilterSelectedTagsContainer>
              </FilterSelectedTagsResizeContainer>
              {!selectedTagsState.rightButtonIsHidden && (
                <CustomChip
                  style={{ backgroundColor: "transparent" }}
                  variant="outlined"
                  disabled={selectedTagsState.rightButtonIsDisabled}
                  onClick={handleScrollTagsRight}
                  label={Icon("action", ArrowRight, 18, null, { margin: 0 })}
                />
              )}
            </>
          )}
        </FilterTagsContainer>
      )}
      <TableView
        getIds={getIds}
        withTypeName={false}
        style={{ padding: null }}
        item={dataItem}
        cantEditCell={cantEditCell}
        nestedDataLoad={props.nestedDataLoad}
        setRowsFn={setRowsFn}
        pushDataFn={props.pushDataFn}
        removeDataFn={props.removeDataFn}
        onRowClick={props.onRowClick}
        recalculateFn={recalculateFn}
        singleRowSelect={singleRowSelect}
        onColumnsDef={onColumnsDef}
        cellRendererContext={{ HAxisContent }}
        isAllowEdit={isAllowEdit}
        editorOk={onEditorOk}
        selectedRowsChanged={onSelectedRowsChanged}
      />
    </>
  );
};

export default DataTableView;
