import React from "react";
import styled from "styled-components";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import { Button, CircularProgress } from "@mui/material";

import { ActionDialog } from "shared/ui";

import DocExportPage from "components/PDF/DocExportPage";

function DocExportLoader({ onOk, indicators }) {
  const { enqueueSnackbar } = useSnackbar();
  const api = useSelector((state) => state.API);

  const [loading, setLoading] = React.useState(false);
  const [isPdfLoaded, setPdfLoaded] = React.useState(false);

  const handlePdfLoaded = React.useCallback(() => setPdfLoaded(true), []);

  const handleDownloadClick = React.useCallback(() => {
    setLoading(true);
    fetch(process.env.REACT_APP_API + "/pdf-web", {
      method: "POST",
      headers: { "Content-Type": "application/json; charset=utf-8" },
      body: JSON.stringify({
        token: api.token,
        pages: [
          `${process.env.REACT_APP_PDF_URL}/documentation/export?registry=indicator_np_fp&indicators=${JSON.stringify(indicators)}&token=${api.token
          }`,
        ],
      }),
    })
      .then((res) => {
        if (!res.ok) return null;
        return res.blob();
      })
      .then((res) => {
        if (!res) {
          enqueueSnackbar("Ошибка", { variant: "error" });
          return;
        }

        const a = document.createElement("a");
        a.download = indicators + ".pdf";
        a.href = URL.createObjectURL(res);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      })
      .finally(() => setLoading(false));
  }, [api, enqueueSnackbar, indicators]);

  return (
    <ActionDialog maxWidth="1160px" onClose={onOk} open title={"Документ"}>
      <Container>
        <PdfContainer>
          <DocExportPage onLoadSuccess={handlePdfLoaded} api={api} indicators={indicators} preview />
        </PdfContainer>
        <DownloadButtonContainer>
          <Button disabled={loading || !isPdfLoaded} onClick={handleDownloadClick} variant="contained">
            Скачать
            {loading && <CircularProgress style={{ marginLeft: 8 }} size={16} />}
          </Button>
        </DownloadButtonContainer>
      </Container>
    </ActionDialog>
  );
}

const Container = styled("div")`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  max-height: 100%;
  gap: 16px;
`;

const PdfContainer = styled("div")`
  flex: 1;
  overflow: auto;
  border: 1px solid #ccc;
  border-radius: 2px;
`;

const DownloadButtonContainer = styled("div")`
  display: flex;
  justify-content: right;
`;

export default React.memo(DocExportLoader);
