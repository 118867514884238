import { action, makeObservable, observable, toJS } from "mobx";
import { AbstractVirtualizedListEntity } from "components/VirtualizedList/VirtualizedListEntity";
import { findVirtualizedListItem, isNullOrUndefined } from "components/VirtualizedList/VirtualizedListEntity/utils";

export interface VirtualizedListDataType {
  label: string;
  id: string | number;
  data: { code: string };
}

export class VirtualizedListEntity extends AbstractVirtualizedListEntity<VirtualizedListDataType> {
  constructor(private api: any, private registry: any, private enqueueSnackbar: any) {
    super();
    makeObservable(this);
  }

  nesting = true;
  dragAndDrop = true;
  dropOnlyToFolders = false;
  @observable haveChanges = false;

  private nestingDataCache: Record<string | number, VirtualizedListDataType[] | null> = {};

  @action.bound async onItemsMove() {
    const targetId = this.dropItem!.id;

    const code = this.selected.map((id) => findVirtualizedListItem(this, this.data, id)!.data.code);
    const target = findVirtualizedListItem(this, this.data, targetId)!.data.code;

    const response = await this.api.send("registries/move", {
      registry: this.registry.code,
      code,
      parent_code: target,
    });

    if (!response || response.error || response.message) {
      this.enqueueSnackbar(response?.message || "Ошибка", { variant: "error" });
      return false;
    }

    let data = [...this.data];

    const parentId = this.nestingParents[this.selected[0]];
    if (isNullOrUndefined(parentId)) {
      data = data.filter((item) => !this.selected.includes(item.id));
    } else {
      const parentCache = this.nestingDataCache[parentId]!;
      this.nestingDataCache[parentId] = parentCache.filter((item) => !this.selected.includes(item.id));
      if (this.nestingDataCache[parentId]!.length === 0) this.nestingDataCache[parentId] = null;
    }

    const targetCache = this.nestingDataCache[targetId] || [];
    this.nestingDataCache[targetId] = [...targetCache, ...this.selectedData];

    this.haveChanges = true;
    this.data = data;
    return true;
  }

  @action.bound refetchItemParent(item: VirtualizedListDataType) {
    const parentId = this.nestingParents[item.id];
    const parent = findVirtualizedListItem(this, this.data, parentId)!;

    delete this.nestingDataCache[parentId];
    if (isNullOrUndefined(parent)) {
      this.data = this.data.map((child) => (child.id === item.id ? item : child));
      return;
    }

    this.collapseFolder(parent);
    this.expandFolder(parent);
  }

  @action.bound async onFolderExpand(item: VirtualizedListDataType): Promise<VirtualizedListDataType[]> {
    const cache = this.nestingDataCache[item.id];
    if (Array.isArray(cache)) return cache;
    if (cache === null) return [];

    const response = await this.api.send("registries/list", {
      code: this.registry.code,
      page_size: 999999,
      page_number: 0,
      sort_order: [],
      filter: [[null, "parent_code", "=", item.data.code, true, null, "AND"]],
    });

    if (!Array.isArray(response) || response.length === 0) {
      this.nestingDataCache[item.id] = null;
      return [];
    }

    const children = response.map((data) => ({ label: data.value, id: data.id, data }));
    this.nestingDataCache[item.id] = children;
    return children;
  }

  isFolder(item: VirtualizedListDataType) {
    const cache = this.nestingDataCache[item.id];
    return cache !== null;
  }

  isFolderEmpty(item: VirtualizedListDataType) {
    const cache = this.nestingDataCache[item.id];
    return Array.isArray(cache) && cache.length === 0;
  }

  folderChildren(item: VirtualizedListDataType): VirtualizedListDataType[] {
    const cache = this.nestingDataCache[item.id];
    return Array.isArray(cache) ? cache : [];
  }
}
