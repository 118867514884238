/* eslint-disable complexity */
import React from "react";
import styled from "styled-components";
import { observer } from "mobx-react-lite";
import { CircularProgress } from "@mui/material";
import { ReactComponent as DropIcon } from "@mdi/svg/svg/plus.svg";
import { ReactComponent as SubmitIcon } from "@mdi/svg/svg/check.svg";
import { ReactComponent as FolderIcon } from "@mdi/svg/svg/folder-outline.svg";
import { ReactComponent as ItemIcon } from "@mdi/svg/svg/focus-field.svg";
import { ReactComponent as ExpandIcon } from "@mdi/svg/svg/chevron-right.svg";
import { ReactComponent as CollapseIcon } from "@mdi/svg/svg/chevron-down.svg";

import { AbstractVirtualizedListEntity } from "../VirtualizedListEntity";

import { useAsyncFn } from "../useAsyncFn";

import { VirtualizedListItemInterface } from "..";

interface ListItemInterface<DataType extends VirtualizedListItemInterface> {
  item: DataType;
  entity: AbstractVirtualizedListEntity<DataType>;
}

function ListItem<DataType extends VirtualizedListItemInterface>({ item, entity }: ListItemInterface<DataType>) {
  const [mousePressEvent, setMousePressEvent] = React.useState<React.MouseEvent<HTMLDivElement, MouseEvent> | null>(null);

  const { dragging } = entity;

  React.useEffect(() => {
    if (dragging) return;
    setMousePressEvent(null);
  }, [dragging]);

  const handleExpand = React.useCallback(
    async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.stopPropagation();
      await entity.expandFolder(item);
    },
    [entity, item]
  );

  const handleCollapse = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.stopPropagation();
      entity.collapseFolder(item);
    },
    [entity, item]
  );

  const [loading, asyncExpandClick] = useAsyncFn(handleExpand);

  const handleMouseEnter = React.useCallback(() => entity.onDropItemEnter(item), [entity, item]);
  const handleMouseLeave = React.useCallback(() => entity.onDropItemLeave(), [entity]);

  const handleMouseDown = React.useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => setMousePressEvent(event), []);

  const handleMouseUp = React.useCallback(() => {
    setMousePressEvent(null);
    if (dragging) return;
    entity.onItemClick(item);
  }, [entity, dragging, item]);

  const handleMouseMove = React.useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (!mousePressEvent) return;

      const distanceX = event.clientX - mousePressEvent.clientX;
      const distanceY = event.clientY - mousePressEvent.clientY;

      if (Math.abs(distanceX) < 3 && Math.abs(distanceY) < 3) return;

      const isItemSelected = entity.isSelected(item);
      if (!isItemSelected) return;

      entity.startDrag();
      entity.updateDragPositions(event.clientX, event.clientY);
    },
    [entity, mousePressEvent, item]
  );

  const handleExpandClick = React.useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      const isFolder = entity.isFolder(item);
      if (isFolder) event.stopPropagation();
    },
    [entity, item]
  );

  const isFolder = entity.isFolder(item);
  const itemDepth = entity.itemDepth(item);
  const canDropItem = entity.canDropItem(item);
  const isItemSelected = entity.isSelected(item);
  const isFolderEmpty = entity.isFolderEmpty(item);
  const isFolderExpanded = entity.isFolderExpanded(item);

  return (
    <ListItemComponent
      onMouseUp={handleMouseUp}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      selected={isItemSelected}
      cantDrop={entity.dragging && !canDropItem}
    >
      <CheckIconContainer selected={isItemSelected || canDropItem}>
        {!isItemSelected && entity.dragging ? <DropIcon /> : <SubmitIcon />}
      </CheckIconContainer>
      {itemDepth > 0 && <div style={{ width: itemDepth * 24, minWidth: itemDepth * 24 }} />}
      {entity.nesting && (
        <ListItemExpand onMouseDown={handleExpandClick} onMouseUp={handleExpandClick}>
          {loading && <CircularProgress color="primary" size={16} />}
          {!loading && isFolder && (
            <ListItemExpandButton disabled={isFolderEmpty} onClick={isFolderExpanded ? handleCollapse : asyncExpandClick}>
              {!isFolderEmpty && isFolderExpanded ? <CollapseIcon /> : <ExpandIcon />}
            </ListItemExpandButton>
          )}
        </ListItemExpand>
      )}
      <ListItemIcon>{isFolder ? <FolderIcon /> : <ItemIcon />}</ListItemIcon>
      <ListItemLabel title={item.label}>{item.label}</ListItemLabel>
    </ListItemComponent>
  );
}

const ListItemComponent = styled("div")<{ selected: boolean; cantDrop: boolean }>`
  display: flex;
  align-items: center;
  gap: 2px;
  background: ${({ selected }) => (selected ? "#f2f2f2" : "transparent")};
  border-left: 2px solid transparent;
  border-right: 2px solid transparent;
  padding: 0 8px 0 4px;
  user-select: none;
  transition: background 0.2s ease-in-out, border 0.2s ease-in-out;

  ${({ selected, cantDrop }) =>
    cantDrop
      ? `
    cursor: no-drop;

    :hover {
      background: ${selected ? "#f2f2f2" : "transparent"};
      border-left: 2px solid transparent;
      border-right: 2px solid transparent;
    }
  `
      : `
    cursor: pointer;

    :hover {
      background: #f2f2f2;
      border-left: 2px solid #2196f3;
      border-right: 2px solid #2196f3;
    }
  `}
`;

const ListItemExpand = styled("div")`
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ListItemIcon = styled("div")`
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 20px;
    height: 20px;
    fill: #747678;
  }
`;

const ListItemLabel = styled("div")`
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  word-break: break-word;
  text-overflow: ellipsis;
  line-height: 16px;
  padding: 2px 0;
  margin-left: 2px;
  letter-spacing: 0.1px;
  transition: color 0.2s ease-in-out;
`;

const ListItemExpandButton = styled("button")`
  border: 0;
  background: transparent;
  cursor: ${({ disabled }) => (disabled ? "auto" : "pointer")};
  transition: background 0.2s ease-in-out;
  padding: 0;
  width: 24px
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  
  :hover {
    background: #f2f2f2;
  }

  svg {
    fill: ${({ disabled }) => (disabled ? "#D7D8D8" : "#747678")};
  }
`;

const CheckIconContainer = styled("div")<{ selected: boolean }>`
  opacity: ${({ selected }) => (selected ? 1 : 0)};
  transition: opacity 0.2s ease-in-out;
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    fill: #0071e3;
  }
`;

export default observer(ListItem) as typeof ListItem;
