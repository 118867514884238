import React from "react";
import styled from "styled-components";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import { observer } from "mobx-react-lite";

import ItemDialog from "components/itemDialog";
import VirtualizedList from "components/VirtualizedList";
import { ActionDialog } from "shared/ui";

import { VirtualizedListEntity } from "./entity";

function VirtualizedListModal({ onOk, reload, schema, title, row, registry }) {
  const { enqueueSnackbar } = useSnackbar();
  const api = useSelector((state) => state.API);

  const entity = React.useMemo(() => new VirtualizedListEntity(api, registry, enqueueSnackbar), [api]);

  React.useEffect(() => {
    entity.updateData([{ label: row.value, id: row.id, data: row }]);
  }, [entity]);

  const lastSelected = React.useMemo(() => {
    if (entity.selectedData.length === 0) return null;
    const lastData = entity.selectedData[entity.selectedData.length - 1];
    return lastData.data;
  }, [entity.selectedData]);

  const handleClose = React.useCallback(() => {
    if (entity.haveChanges) reload();
    onOk();
  }, [entity.haveChanges, onOk, reload]);

  const handleDataEdit = async (result) => {
    const data = result?.v;
    const response = await api.call("registries/edit", { code: registry.code, data });

    if (!response || response?.error) {
      enqueueSnackbar(response?.message || "Ошибка", { variant: "error" });
      return;
    }

    const item = { label: data.value, id: data.id, data };
    entity.refetchItemParent(item);
  };

  return (
    <ActionDialog maxWidth={1200} contentStyle={{ padding: 0 }} onClose={handleClose} open title={title}>
      <Container>
        <ListContainer>
          <VirtualizedList entity={entity} />
        </ListContainer>
        <CardContainer>
          <ItemDialog schema={schema} onOk={handleDataEdit} prop={{ current: { properties: lastSelected } }} isEmbeded />
        </CardContainer>
      </Container>
    </ActionDialog>
  );
}

const Container = styled("div")`
  display: flex;
  width: calc(100% - 48px);
  height: 70vh;
  max-height: 70vh;
  overflow: hidden;
  margin: 24px;
  gap: 24px;
`;

const ListContainer = styled("div")`
  display: flex;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  padding: 8px 0;
  flex: 1;
`;

const CardContainer = styled("div")`
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  flex: 1;
`;

export default observer(VirtualizedListModal);
