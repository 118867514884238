import React from "react";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";

import { ContourBackdrop } from "shared/ui";

function DownloadFile({ onClose, code, payload, withoutTranslation }) {
  const { enqueueSnackbar } = useSnackbar();
  const api = useSelector(state => state.API);
  
  const downloadFile = React.useCallback(async (response, filename) => {
    if (!response.ok) {
      enqueueSnackbar("Файл не существует", { variant: "error" });
      onClose();
      return;
    }

    const blob = await response.blob();
    const a = document.createElement("a");

    if (filename) {
      a.download = filename;
    } else {
      try {
        const contentDisposition = response.headers.get('Content-Disposition');
        const filename = contentDisposition.match(/filename="([^"]+)"/)[1];
        a.download = filename;
      } catch (error) {}
    }

    a.href = URL.createObjectURL(blob);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    onClose();
  }, [enqueueSnackbar, onClose]);

  const checkFile = React.useCallback(async (filename) => {
    await new Promise((resolve) => setTimeout(resolve, 5000));

    const response = await fetch(process.env.REACT_APP_API + "/registries/finish_download", {
      method: "POST",
      headers: { "Content-Type": "application/json; charset=utf-8" },
      body: JSON.stringify({ token: api.token, filename }),
    });

    try {
      const jsonResult = await response.clone().json();
      if (jsonResult === null || jsonResult?.ok === null) {
        enqueueSnackbar("Не удалось скачать файл", { variant: "error" });
        onClose();
        return;
      }

      if (jsonResult?.ok === false) {
        checkFile(filename);
        return;
      }
    } catch (error) {}

    downloadFile(response, filename);
  }, [api, downloadFile, enqueueSnackbar, onClose]);

  const loadFile = React.useCallback(async () => {
    const path = withoutTranslation ? "download_without_translat" : "download";
    const response = await fetch(process.env.REACT_APP_API + `/registries/${path}`, {
      method: "POST",
      headers: { "Content-Type": "application/json; charset=utf-8" },
      body: JSON.stringify({
        token: api.token,
        code,
        ...payload,
      }),
    });

    try {
      const jsonResult = await response.clone().json();
      if (typeof jsonResult?.filename === "string") {
        checkFile(jsonResult.filename);
        return;
      }
    } catch (error) {}

    downloadFile(response);
  }, [api, code, payload, downloadFile, checkFile]);

  React.useEffect(() => {
    loadFile();
  }, [loadFile]);

  return <ContourBackdrop zIndex={100000} />;
}

export default React.memo(DownloadFile);
