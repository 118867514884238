import { VirtualizedListItemInterface } from "../";
import { AbstractVirtualizedListEntity } from "./";

export function isNullOrUndefined<ValueType>(value: ValueType) {
  return value === null || value === undefined;
}

export function virtualizedListItemParents<DataType extends VirtualizedListItemInterface>(
  entity: AbstractVirtualizedListEntity<DataType>,
  item: DataType
) {
  let parent = entity.nestingParents[item.id];
  if (isNullOrUndefined(parent)) return [];

  const parents = [parent];

  while (!isNullOrUndefined(parent)) {
    const nextParent = entity.nestingParents[parent];
    if (!isNullOrUndefined(nextParent)) parents.push(nextParent);
    parent = nextParent;
  }

  return parents;
}

export function findVirtualizedListItem<DataType extends VirtualizedListItemInterface>(
  entity: AbstractVirtualizedListEntity<DataType>,
  data: DataType[],
  id: number | string
): DataType | null {
  for (let i = 0; i < data.length; i++) {
    const item = data[i];
    if (item.id === id) return item;

    const children = entity.folderChildren(item);
    if (!children) continue;

    const result = findVirtualizedListItem(entity, children, id);
    if (result) return result;
  }

  return null;
}

export function virtualizedListDataWithChildren<DataType extends VirtualizedListItemInterface>(
  entity: AbstractVirtualizedListEntity<DataType>,
  data: DataType[]
) {
  let folders: DataType[] = [];
  const items: DataType[] = [];

  data.forEach((item) => {
    if (!entity.isFolder(item)) {
      items.push(item);
      return;
    }

    folders.push(item);
    const expanded = entity.isFolderExpanded(item);
    if (!expanded) return;

    const children = entity.folderChildren(item);
    if (!children) return;

    const childrenList = virtualizedListDataWithChildren(entity, children);
    if (childrenList) folders = folders.concat(childrenList);
  });

  return folders.concat(items);
}
